<template>
  <b-row>
    <b-col>
      <b-card>
        <validation-observer ref="specialCommoditiesValidation">
          <b-form>

            <b-row>
              <b-col
                v-if="companyList.length > 1"
              >
                <b-form-group
                  label="Company"
                  label-for="company"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Company"
                    rules="required"
                  >
                    <v-select
                      id="company"
                      v-model="selectedCompany"
                      :options="companiesList"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Country code"
                  label-for="country-code"
                  :description="type.value === 'replace_description' ? '* Leave blank to include all countries' : '' "
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Country code"
                    :rules="type.value === 'replace_description' ? 'min:2|max:2' : 'required|min:2|max:2'"
                  >
                    <b-form-input
                      id="country-code"
                      v-model="countryCode"
                      name="country-code"
                      :state="errors.length > 0 ? false:null"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col v-if="type.value !== 'replace_description'">
                <b-form-group
                  label="HS Code"
                  label-for="hs-code"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="HS Code"
                    rules="required|max:10|min:2"
                  >
                    <b-form-input
                      id="hs-code"
                      v-model="hsCode"
                      name="hs-code"
                      :state="errors.length > 0 ? false:null"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Description"
                  label-for="Description"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Description"
                    :rules="type.value === 'replace_description' ? 'required|max:255': 'max:255'"
                  >
                    <b-form-input
                      id="description"
                      v-model="description"
                      name="description"
                      :state="errors.length > 0 ? false:null"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Type"
                  label-for="type"
                >
                  <v-select
                    id="type"
                    v-model="type"
                    label="text"
                    :options="typeOptions"
                  />
                </b-form-group>
              </b-col>

              <b-col v-if="type.value === 'reduced_vat'">
                <b-form-group
                  label="Reduced VAT Code"
                  label-for="reduced-vat-code"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Reduced VAT Code"
                  >
                    <b-form-input
                      id="reduced-vat-code"
                      v-model="additionalData.reduced_vat"
                      name="reduced-vat-code"
                      :state="errors.length > 0 ? false:null"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col v-if="type.value === 'prohibited'">
                <b-form-group
                  label="Origin country"
                  label-for="origin-country"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Origin country"
                  >
                    <b-form-input
                      id="origin-country"
                      v-model="additionalData.origin_country"
                      name="origin-country"
                      :state="errors.length > 0 ? false:null"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col v-if="type.value === 'replace_hs_code'">
                <b-form-group
                  label="Replace HS code to"
                  label-for="replace-hs-code-to"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Replace HS code to"
                    rules="required|max:6|min:6"
                  >
                    <b-form-input
                      id="replace-hs-code-to"
                      v-model="additionalData.replace_hs_code_to"
                      name="replace-hs-code-to"
                      :state="errors.length > 0 ? false : null"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col v-if="type.value === 'replace_description'">
                <b-form-group
                  label="Replace Description to"
                  label-for="replace-description-to"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Replace Description to"
                    rules="required|max:255"
                  >
                    <b-form-input
                      id="replace-description-to"
                      v-model="additionalData.replace_description_to"
                      name="replace-description-to"
                      :state="errors.length > 0 ? false : null"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-button
                  type="button"
                  variant="primary"
                  @click="validationForm"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import handleError from '@/views/components/errorHandler'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BCard,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      selectedCompany: null,
      countryCode: '',
      companyList: [],
      hsCode: '',
      description: '',
      type: { text: 'Reduced VAT', value: 'reduced_vat' },
      additionalData: {
        reduced_vat: null,
        origin_country: null,
        replace_hs_code_to: null,
        replace_description_to: null,
      },
      typeOptions: this.$classifiers().getOptions('special_commodities.types', false),
    }
  },
  computed: {
    companiesList() {
      const filtered = []
      this.companyList.forEach(item => {
        filtered.push({
          label: item.name,
          value: item.id,
        })
      })

      return filtered
    },
  },
  created() {
    this.companyList = this.$profile().data.user.companies
  },
  methods: {
    validationForm() {
      this.$refs.specialCommoditiesValidation.validate().then(success => {
        if (!success) {
          return
        }
        this.insert()
      })
    },
    insert() {
      const additionalData = {}
      if (this.$data.type.value === 'reduced_vat') {
        additionalData.reduced_vat = this.$data.additionalData.reduced_vat
      } else if (this.$data.type.value === 'replace_hs_code') {
        additionalData.replace_hs_code_to = this.$data.additionalData.replace_hs_code_to
      } else if (this.$data.type.value === 'replace_description') {
        this.hsCode = ''
        additionalData.replace_description_to = this.$data.additionalData.replace_description_to
      } else {
        additionalData.origin_country = this.$data.additionalData.origin_country
      }
      this.$http.post('/v1/special-commodities', {
        companyId: this.selectedCompany?.value ?? this.$activeCompany().data.company.id,
        hsCode: this.$data.hsCode,
        description: this.$data.description || null,
        countryCode: this.$data.countryCode,
        type: this.$data.type.value,
        additionalData: additionalData,
      })
        .then(() => {
          this.$router.push('/special-commodities')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Commodity added',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
